import React, { createElement } from 'react';
import { Transition } from 'react-transition-group';
import getTransitionStyle from './src/utils/getTransitionStyle';

import './src/polyfills';

const timeout = 500;


class ReplaceComponentRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { exiting: false, nextPageResources: {} };
    this.listenerHandler = this.listenerHandler.bind(this);
  }

  listenerHandler(event) {
    // dont do anything if clicking on a link to the same page
    if (this.props.location.pathname === event.detail.pathname) {
      return;
    }
    const nextPageResources =
      this.props.loader.getResourcesForPathname(
        event.detail.pathname,
        nextPageResources => this.setState({ nextPageResources })
      ) || {};
    this.setState({ exiting: true, nextPageResources });
  }

  componentDidMount() {
    // window.addEventListener(historyExitingEventType, this.listenerHandler);
  }

  componentWillUnmount() {
    // window.removeEventListener(historyExitingEventType, this.listenerHandler);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.key !== nextProps.location.key) {
      this.setState({ exiting: false, nextPageResources: {} });
    }
  }

  render() {
    // was getting flash of exiting page coming back, so added buffer time to exit
    const transitionProps = {
      timeout: {
        enter: 0,
        exit: timeout + 50,
      },
      appear: false,
      in: !this.state.exiting,
      key: this.props.location.key,
    };
    return (
      <Transition {...transitionProps}>
        {status =>
          createElement(this.props.pageResources.component, {
            ...this.props,
            ...this.props.pageResources.json,
            transition: {
              status,
              timeout,
              style: getTransitionStyle({ status, timeout }),
              nextPageResources: this.state.nextPageResources,
            },
          })
        }
      </Transition>
    );
  }
}

// eslint-disable-next-line react/display-name
export const wrapPageElement = ({ props, loader }) => {
  if (props.layout) {
    return undefined;
  }
  return createElement(ReplaceComponentRenderer, { ...props, loader });
};
